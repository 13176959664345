import request from '@/utils/request'

const tradeSellContractApi = {
  trade_sell_contract_list: '/trade/sell_contract/',
  trade_sell_contract_create: '/trade/sell_contract/',
  trade_sell_contract_update: '/trade/sell_contract/',
  trade_sell_contract_delete: '/trade/sell_contract/',
  trade_sell_contract_archived: '/trade/sell_contract_archived/'
}

/**
 * 列表
 */
export function sell_contract_list (parameter) {
  return request({
    url: tradeSellContractApi.trade_sell_contract_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function sell_contract_create (parameter) {
  return request({
    url: tradeSellContractApi.trade_sell_contract_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function sell_contract_update (parameter, sell_contract_id) {
  return request({
    url: tradeSellContractApi.trade_sell_contract_update + sell_contract_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function sell_contract_delete (sell_contract_id) {
  return request({
    url: tradeSellContractApi.trade_sell_contract_delete + sell_contract_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 购销合同修改状态
 * @param parameter
 * @param sell_contract_id
 * @returns {AxiosPromise}
 */
export function sell_contract_archived (parameter) {
  return request({
    url: tradeSellContractApi.trade_sell_contract_archived,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
